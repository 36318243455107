import React, {useState, useContext, useEffect } from "react"
import ThemeContext from "../utils/theme"
import { PageLayout } from "../components"
import { SEO } from "../utils"
import { Container, Image, InputGroup, FormControl, Form, Button, Modal, CloseButton, ProgressBar} from "react-bootstrap"
import { Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import firebase from "../components/firebase"

import { UserData }  from  "../components/UserData"
import 'bootstrap/dist/css/bootstrap.css'; 
import "bootstrap/dist/css/bootstrap.min.css";

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import './grid.css';


import { loggedUser, printing } from  "../components/FirebaseAuth"
import { LoadStateHouse, LoadRandomHouse } from  "../components/LoadStateHouse"
import { navigate } from "@reach/router"


const GetHouseData = async() =>
{

  let searchParams
  if (typeof window !== 'undefined') 
  {  searchParams = new URLSearchParams(window.location.search);  }

  
  let home1Params = searchParams.get('home1')
  let divider1 = home1Params.search("_")
  let mlsParam1 = home1Params.substring(0,divider1)
  let stateParam1 = home1Params.substring((divider1+1))

  let home2Params = searchParams.get('home2')
  let divider2 = home2Params.search("_")
  let mlsParam2 = home2Params.substring(0,divider2)
  let stateParam2 = home2Params.substring((divider2+1))

  let altReturn ={}
  

  await firebase.firestore().collection('States').doc(String(stateParam1)).collection('buffer').doc(mlsParam1).get()
  .then((doc) => 
  {
    if (doc.exists) 
    {
        //console.log("Document data:", doc.data());
        altReturn["home1"] = doc.data()
    } 
    else 
    {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }).catch((error) => {
      console.log("Error getting document:", error);
  });

  await firebase.firestore().collection('States').doc(String(stateParam2)).collection('buffer').doc(mlsParam2).get()
  .then((doc) => 
  {
    if (doc.exists) 
    {
        //console.log("Document data:", doc.data());
        altReturn["home2"] = doc.data()
    } 
    else 
    {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }).catch((error) => {
      console.log("Error getting document:", error);
  });


  return altReturn

}


export default ({location}) => {

  let currentUser = loggedUser()

  //let userData = UserData()
  const [userData, setUserData] = useState({})

  const[mlsID,setmlsID] = useState("")

  const [home1Data,setHome1] = useState({});
  const [home1pics, setHome1Pics] = useState([]);
  const [home1feats, setHome1Feats] = useState([{category:"filler",text:["filler", "filler"]}]);

  const [home2Data,setHome2] = useState({});
  const [home2pics, setHome2Pics] = useState([]);
  const [home2feats, setHome2Feats] = useState([{category:"filler",text:["filler", "filler"]}]);

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedHouse, setSelectedHouse] = useState('');
  const [notSelectedHouse, setNotSelectedHouse] = useState('');

  const [choiceResult, setChoiceResult] = useState('');

  const { dark } = useContext(ThemeContext)
  const [rerendering, setRerender] = useState(0.0)

  const loadHome1Data = (pageData) =>
  {
    setHome1(pageData)
    let tempPic = []
    pageData.photos.forEach(element => {
      let str = element.href
      let trim = str.substring(0, (str.length-5))
      let better = trim.concat("od-w1024_h768_x2.webp")
      tempPic.push({src:better})
    });

    setHome1Feats(pageData.features)
    setHome1Pics(tempPic)
  }
  const loadHome2Data = (pageData) =>
  {
    setHome2(pageData)
    let tempPic = []
    pageData.photos.forEach(element => {
      let str = element.href
      let trim = str.substring(0, (str.length-5))
      let better = trim.concat("od-w1024_h768_x2.webp")
      tempPic.push({src:better})
    });

    setHome2Feats(pageData.features)
    setHome2Pics(tempPic)
  }

  function Comma(Num) 
  { //function to add commas to textboxes
    if (Num == null)
    {
      Num = ''
    }
    Num += '';
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    let x = Num.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    return x1 + x2;
  }

  useEffect(() => 
  {
    
    ( async () => { 

      let getHouse = await GetHouseData() 
      loadHome1Data(getHouse["home1"])
      loadHome2Data(getHouse["home2"])

    })()

  }, [mlsID, rerendering])

  let houseSize1 = 0
  let houseUnits1 = ""
  let housePrice1 = 0
  let houseCity1 = ""
  let houseState1 = ""
  let houseLine1 = ""
  let houseZip1 = ""
  let houseLat1 = 0
  let houseLon1 = 0


  if(home1Data)
  {
    housePrice1 = home1Data.price
    if(home1Data.building_size)
    {
      houseSize1 = home1Data.building_size.size
      houseUnits1 = home1Data.building_size.units
    }
    if(home1Data.address)
    {
      houseCity1 = home1Data.address.city
      houseState1 = home1Data.address.state
      houseLine1 = home1Data.address.line
      houseZip1 = home1Data.address.postal_code
      houseLat1 = home1Data.address.lat
      houseLon1 = home1Data.address.lon
    }
  }

  let houseSize2 = 0
  let houseUnits2 = ""
  let housePrice2 = 0
  let houseCity2 = ""
  let houseState2 = ""
  let houseLine2 = ""
  let houseZip2 = ""
  let houseLat2 = 0
  let houseLon2 = 0

  if(home2Data)
  {
    housePrice2 = home2Data.price
    if(home2Data.building_size)
    {
      houseSize2 = home2Data.building_size.size
      houseUnits2 = home2Data.building_size.units
    }
    if(home2Data.address)
    {
      houseCity2 = home2Data.address.city
      houseState2 = home2Data.address.state
      houseLine2 = home2Data.address.line
      houseZip2 = home2Data.address.postal_code
      houseLat2 = home2Data.address.lat
      houseLon2 = home2Data.address.lon
    }
  }


  const SubmitAnswer = async (evt) => 
  {
    //if(evt)
    //{ evt.preventDefault(); }

    let tempSelected = String(evt)

    let price1 = housePrice1 
    let price2 = housePrice2
    let win = false

    if (price1 > price2 && tempSelected=='A')
    {
      //console.log("You Chose the Correct House!!")
      setChoiceResult('Right')
      win = true
    }
    
    else if (price1 > price2 && tempSelected=='B')
    {
      //console.log("You Chose the Wrong House")
      setChoiceResult('Wrong')
    }
    
    else if (price1 < price2 && tempSelected=='A')
    {
      //console.log("You Chose the Wrong House")
      setChoiceResult('Wrong')
    }
    
    else if (price1 < price2 && tempSelected=='B')
    {
      //console.log("You Chose the Correct House!!")
      setChoiceResult('Right')
      win = true
    }

    if (win)
    {
      if(currentUser && currentUser.uid)
      {
        let userID = `${currentUser.uid}`
        let previousData = {}

        let userStorage = JSON.parse(localStorage.getItem('userData'))

        if (userStorage == null)
        {
          //console.log("RUNNING Users FIREBASE CALL")
          await firebase.firestore().collection('Users').doc(String(currentUser.uid)).get()
          .then((doc) => {
            if (doc.exists) 
            {
                //console.log("Document data:", doc.data());
                //doc.data()
                previousData = doc.data()
                localStorage.setItem('userData', JSON.stringify(doc.data()) )
            } 
            else 
            {
                // doc.data() will be undefined in this case
                console.log("No such user!");
            }
          }).catch((error) => {
              console.log("Error getting user:", error);
          });
        }
        else 
        {
          previousData = userStorage
          //console.log("didnt run Submit Price Users firebase read because data is in local storage", userStorage)
        }
        
        let searchParams
        if (typeof window !== 'undefined') 
        {  searchParams = new URLSearchParams(window.location.search);  }


        let home1Params = searchParams.get('home1')
        let divider1 = home1Params.search("_")
        let mlsParam1 = home1Params.substring(0,divider1)
        let stateParam1 = home1Params.substring((divider1+1))

        let home2Params = searchParams.get('home2')
        let divider2 = home2Params.search("_")
        let mlsParam2 = home2Params.substring(0,divider2)
        let stateParam2 = home2Params.substring((divider2+1))

        let ordering = String(mlsParam1).localeCompare(String(mlsParam2))
        //console.log("ordering is", ordering)
        let keyname
        if(ordering == 1)
        {
          keyname = "compare".concat(mlsParam1).concat(".").concat(stateParam1).concat("+").concat(mlsParam2).concat(".").concat(stateParam2)
        }
        else
        {
          keyname = "compare".concat(mlsParam2).concat(".").concat(stateParam2).concat("+").concat(mlsParam1).concat(".").concat(stateParam1)
        }
        //console.log("the key is", keyname)

        previousData[keyname] = [100]

        //console.log("entry would be", previousData)
        //console.log("SETTING USERS FIREBASE CALL")

        firebase.firestore().collection('Users').doc(String(userID)).set(previousData)
        localStorage.setItem('userData', JSON.stringify(previousData) )
        
        //console.log("updated userData in localstorage", localStorage)
      }
    }

  }

  const LoadNewCompare = async (evt) => 
  {
    let mode = 'return'
    let [randomHome1,randomHome2] = await LoadRandomHouse(userData,mode)

    //console.log("the two new homes are",randomHome1,randomHome2)

    setSelectedHouse('')
    setNotSelectedHouse('')
    setConfirmShow(false)
    setChoiceResult('')

    navigate(`/compare?home1=${randomHome1}&home2=${randomHome2}`)

    setTimeout(() => 
    {  
      let num = Math.random() + Math.random() + Math.random()
      setRerender(num)
      //console.log("Called rerender")
    }, 100);
    
  }

  function ConfirmationModal(props) 
  {
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
          { (() => 
            {
              if(choiceResult == '')
              {
                /*
                return(
                  <>
                    You selected:<span style={{color:'#00dd00',fontSize:'2rem'}}> {selectedHouse}</span> costs more than <span style={{color:'#00dd00',fontSize:'2rem'}}>{notSelectedHouse}</span> 
                  </>
                );
                */
              }
              else if(choiceResult == 'Right')
              {
                return(
                  <>
                    Correct!!! 😀
                  </>
                );
              }
              else if(choiceResult == 'Wrong')
              {
                return(
                  <>
                    Sorry 😢
                  </>
                );
              }
            })()
          }
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          { (() => 
            {
              if(choiceResult == '')
              {
                /*
                return(
                  <>
                    <p className="thicc">
                      Click 'Yes' to confirm or 'Close' to change your answer.
                    </p>
                    <br/>
                    <Button onClick={SubmitAnswer} style={{ fontSize:"1.5rem",fontWeight:"500",color:"white"}} variant="primary">
                      Yes!
                    </Button>
                  </>
                );
                */
              }
              else if(choiceResult == 'Right')
              {
                return(
                  <>
                    <h4>
                      You scored 100 points!!!! <br/>
                      House A is <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice1).replace(/\D/g,''))} </span> 
                      and House B is <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice2).replace(/\D/g,''))}</span>
                    </h4>
                  </>
                );
              }
              else if(choiceResult == 'Wrong')
              {
                return(
                  <>
                    <h4>
                      That is incorrect. Better luck next time. <br/>
                      House A is <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice1).replace(/\D/g,''))} </span> 
                      and House B is <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice2).replace(/\D/g,''))}</span>
                    </h4>
                  </>
                );
              }
            })()
          }
          
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          { (() => 
            {
              if(choiceResult == '')
              {
                return(
                  <>
                    <Button variant="danger" onClick={props.onHide}>Close</Button>
                  </>
                );
              }
              else
              {
                return(
                  <>
                  <table>
                    <tr>
                      
                      <td>
                        <Button onClick={() =>
                          {
                            LoadNewCompare()
                          }
                        } style={{backgroundColor:"#00BB00",borderWidth:"0px",borderColor:"#00BB00"}} variant="success" value="Submit">
                          New Comparison
                        </Button> 
                      </td>

                      <td>
                        <Button variant="danger" style={{borderWidth:"0px"}} onClick={() => 
                          {
                            props.onHide()
                            //setChoiceResult('')
                          }
                        }>Close</Button>
                      </td>

                    </tr>
                  </table>
                  </>
                );
              }
            })()
          }
          
        </Modal.Footer>
      </Modal>
    );
  }

  

  
  return (
    <PageLayout>
      <SEO title="Compare" />

      { (() => 
        {
          if(choiceResult == '')
          {
            return(
              <>
                <div className="inputCompare text-color">
                  <h3>  Which Price is Higher? </h3> 
                  <InputGroup style={{ justifyContent : 'center'}}>
                    <Button onClick={(e) =>
                      {
                        SubmitAnswer('A')
                        //setSelectedHouse('A')
                        //setNotSelectedHouse('B')
                        setConfirmShow(true)
                      }
                    } style={{ fontSize:"1.5rem",fontWeight:"900", backgroundColor:"#00BB00",borderColor:"#00BB00",width:'40%'}} variant="success" value="Submit">
                      A
                    </Button> 
                    <Button disabled style={{backgroundColor:'grey',borderColor:"grey", color:'white', fontSize:"1.2rem",fontWeight:"900",width:'20%',textAlign:'center'}}>
                      OR
                    </Button>
                    <Button onClick={(e) =>
                      {
                        SubmitAnswer('B')
                        //setSelectedHouse('B')
                        //setNotSelectedHouse('A')
                        setConfirmShow(true)
                      }
                    } style={{ fontSize:"1.5rem",fontWeight:"900", backgroundColor:"#00BB00",borderColor:"#00BB00",width:'40%'}} variant="success" value="Submit">
                      B
                    </Button>
                  </InputGroup>
                </div>
              </>
            );
          }
          else
          {
            return(
              <>
                <div className="inputCompare text-color">
                  <InputGroup style={{ justifyContent : 'center'}}>
                    <Button onClick={() =>
                      {
                        LoadNewCompare()
                      }
                    } style={{ fontSize:"1.5rem",fontWeight:"900", backgroundColor:"#00BB00",borderColor:"#00BB00",width:'90%'}} variant="success" value="Submit">
                      New Comparison
                    </Button> 
                  </InputGroup>
                </div>
              </>
            );
          }
        })()
      }
      

      {confirmShow ?  <ConfirmationModal
                            show={confirmShow}
                            onHide={() => setConfirmShow(false)}
                          /> : ` `}


      <br/>
      
      
      <div className="pageMargin pt-6 mt-6 text-color">
        <div className="compareTop-grid">
          <div className="compareTopBox">
            { (() => 
              {
                if(choiceResult == '')
                {
                  return(
                    <>
                      <h2> A </h2>
                    </>
                  );
                }
                else
                {
                  return(
                    <>
                      <h2> <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice1).replace(/\D/g,''))} </span> </h2>
                    </>
                  );
                }
              })()
            }
             

            <div className="compareBasic-grid">            
              <div className="compareBasicBoxes ">
                <Carousel images={home1pics} style={{ height: 400, backgroundColor:'transparent' }} hasMediaButton={false} hasIndexBoard={false} />
              </div>

              <div className="compareBasicBoxes text-left thicc " >
                <hr style={{ borderTop:`1px solid ${dark ? 'white' : 'black'}`}}/>
                    {/*houseLine1*/} {houseCity1}, {houseState1}, {houseZip1} <br/>
                    
                    {home1Data.beds} <div className="thin">Beds</div> {home1Data.baths} <div className="thin">Baths</div>&nbsp; 
                    {houseSize1} <div className="thin">{houseUnits1}</div> {home1Data.style} <br/>

                    {home1Data.year_built} <div className="thin">Year Built</div> 
              </div>

              
              <Accordion >
                <Accordion.Item eventKey="0" style={{borderColor:'#bbb',borderWidth:'1px'}}>
                  <Accordion.Button style={{ backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}` }}><u>Description:</u></Accordion.Button>
                  <Accordion.Body >
                    <div className="compareBasicBoxes text-left thicc scrolling">
                      <div className="thin">{home1Data.description}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              
              
              <div className="compareBasicBoxes text-left thicc ">
                <Accordion >
                  <Accordion.Item eventKey="0" style={{borderColor:'#bbb',borderWidth:'1px'}}>
                    <Accordion.Button style={{ backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}` }}><u>Details:</u></Accordion.Button>
                    <Accordion.Body >
                      <div className="thin feat-grid">
                        { 
                          home1feats.map((e,ind) => 
                          {
                            return (
                              <div key={`featCat-${ind}`} className="featBoxes" >
                                <div className="mid">{e.category} </div>
                                <ul>
                                  {
                                    e.text.map((f,index) =>
                                    {
                                      return (
                                        <li key={`featList-${index}`}>
                                          {f}
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="compareTopBox">  
            { (() => 
              {
                if(choiceResult == '')
                {
                  return(
                    <>
                      <h2> B </h2>
                    </>
                  );
                }
                else
                {
                  return(
                    <>
                      <h2> <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice2).replace(/\D/g,''))} </span> </h2>
                    </>
                  );
                }
              })()
            } 

            <div className="compareBasic-grid">
              <div className="compareBasicBoxes">
                <Carousel images={home2pics} style={{ height: 400, backgroundColor:'transparent' }} hasMediaButton={false} hasIndexBoard={false} />
              </div>

              <div className="compareBasicBoxes text-left thicc " >
                <hr style={{ borderTop:`1px solid ${dark ? 'white' : 'black'}`}}/>
                    {/*houseLine2*/} {houseCity2}, {houseState2}, {houseZip2} <br/>
                    
                    {home2Data.beds} <div className="thin">Beds</div> {home2Data.baths} <div className="thin">Baths</div>&nbsp; 
                    {houseSize2} <div className="thin">{houseUnits2}</div> {home2Data.style} <br/>

                    {home2Data.year_built} <div className="thin">Year Built</div>
              </div>

              <Accordion >
                <Accordion.Item eventKey="0" style={{borderColor:'#bbb',borderWidth:'1px'}}>
                  <Accordion.Button style={{ backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}` }}><u>Description:</u></Accordion.Button>
                  <Accordion.Body >
                    <div className="compareBasicBoxes text-left thicc scrolling">
                      <div className="thin">{home2Data.description}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              
              <div className="compareBasicBoxes text-left thicc ">
                <Accordion >
                  <Accordion.Item eventKey="0" style={{borderColor:'#bbb',borderWidth:'1px'}}>
                    <Accordion.Button style={{ backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}` }}><u>Details:</u></Accordion.Button>
                    <Accordion.Body >
                      <div className="thin feat-grid">
                        { 
                          home2feats.map((e,ind) => 
                          {
                            return (
                              <div key={`featCat-${ind}`} className="featBoxes" >
                                <div className="mid">{e.category} </div>
                                <ul>
                                  {
                                    e.text.map((f,index) =>
                                    {
                                      return (
                                        <li key={`featList-${index}`}>
                                          {f}
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )

}